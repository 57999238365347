@media (max-width: 500px) {
    .logo-container {
      margin-left: 20px; /* Adjust the margin-left as needed */
    }
  }
  

  @media (max-width: 700px) {
    .image-container {
      margin-left: 70px; /* Adjust the left margin as needed */
    }
    .custom-list{
      margin-left: -30px;
    }
    
  }

  @media (max-width: 400px) {
    .image-container {
      margin-left: 55px; /* Adjust the left margin as needed */
    }
   
  }

.social{
  margin-right: 650px;
}

@media (max-width: 700px) {
  .social{
    margin-right: 0px;

  }
  
  
}



@media (max-width: 600px) {
  .mobile-font-size {
      font-size: 1.0rem; /* Adjust this value as needed */
  }
  .c1{
    margin-left: 22px;
  }
  
}
/* App.css */

/* Ensure this rule applies only in responsive mode */
@media (max-width: 600px) {
  .navbar-toggler {
    margin-right: 20px; /* Adjust the margin as needed */
  }
  .nav-link {
    margin-left: 10px; /* Adjust the margin as needed */
  }
  #preg{
    margin-top: 15px;
  }
  #px{
    margin-top: 15px;
  }
}

@media (max-width: 400px){
  .c1{
    margin-left: 25px;
  }
  .navbar-toggler {
    margin-right: 20px; /* Adjust the margin as needed */
  }
  #preg{
    margin-top: 15px;
  }

  #px{
    margin-top: 15px;
  }
}


@media (min-width: 601px) {
  .navbar-toggler {
    margin-right: 30px; /* Adjust the margin as needed */
  }
  .nav-link {
    margin-left: 30px; /* Adjust the margin as needed */
  }
  .logo-container {
    margin-left: 20px; /* Adjust the margin-left as needed */
  }
}